import { Component, OnInit, ViewChild } from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { AppMinimize } from '@ionic-native/app-minimize/';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  constructor(private platform: Platform, private appMinimize: AppMinimize, private location: Location) {}
  ngOnInit()
  {
    this.initbacklisten();
  }
  initbacklisten()
  {
    this.platform.ready().then(()=>{
      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('BACK BUTTON PRESSED');
        if (this.routerOutlet.canGoBack()){
          console.log('GOING BACK');
          this.location.back();
        }
        else
        {
          this.appMinimize.minimize();
        }
     });
    });
  }

}
