import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppMinimize } from '@ionic-native/app-minimize/';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';



@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AppMinimize, PhotoViewer, CallNumber],
  bootstrap: [AppComponent],
})
export class AppModule {}
