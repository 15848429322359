import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./components/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'allproducts',
    loadChildren: () => import('./components/allproducts/allproducts.module').then( m => m.AllproductsPageModule)
  },
  {
    path: 'searchproducts',
    loadChildren: () => import('./components/searchproducts/searchproducts.module').then( m => m.SearchproductsPageModule)
  },
  {
    path: 'searchproducts/:sterms',
    loadChildren: () => import('./components/searchproducts/searchproducts.module').then( m => m.SearchproductsPageModule)
  },
  {
    path: 'catproducts',
    loadChildren: () => import('./components/catproducts/catproducts.module').then( m => m.CatproductsPageModule)
  },
  {
    path: 'catproducts/:id',
    loadChildren: () => import('./components/catproducts/catproducts.module').then( m => m.CatproductsPageModule)
  },
  {
    path: 'viewproduct',
    loadChildren: () => import('./components/viewproduct/viewproduct.module').then( m => m.ViewproductPageModule)
  },
  {
    path: 'viewproduct/:id',
    loadChildren: () => import('./components/viewproduct/viewproduct.module').then( m => m.ViewproductPageModule)
  },
  {
    path: 'allcats',
    loadChildren: () => import('./components/allcats/allcats.module').then( m => m.AllcatsPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./components/contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'alloffers',
    loadChildren: () => import('./components/alloffers/alloffers.module').then( m => m.AlloffersPageModule)
  },
  {
    path: 'goldscheme',
    loadChildren: () => import('./components/goldscheme/goldscheme.module').then( m => m.GoldschemePageModule)
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./components/myaccount/myaccount.module').then( m => m.MyaccountPageModule)
  },
  {
    path: 'viewscheme',
    loadChildren: () => import('./components/viewscheme/viewscheme.module').then( m => m.ViewschemePageModule)
  },
  {
    path: 'viewscheme/:id',
    loadChildren: () => import('./components/viewscheme/viewscheme.module').then( m => m.ViewschemePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
